<template>
  <div>
    <a-modal :getContainer="getContainer" v-model:visible="visible" title="修改密码" @cancel="close" :keyboard="true"
             :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800" :zIndex="1">
      <a-spin :spinning="spinning">
        <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item name="operatorName" label="用户名" has-feedback>
            <a-input v-model:value="form.operatorName" placeholder="请输入用户名"></a-input>
          </a-form-item>
          <a-form-item name="currentPassword" label="旧密码" has-feedback>
            <a-input v-model:value="form.currentPassword" placeholder="请输入旧密码" type="password"></a-input>
          </a-form-item>
          <a-form-item name="password" label="新密码" has-feedback>
            <a-input v-model:value="form.password" placeholder="必须包含大小写字母和数字且不低于6位" type="password"></a-input>
          </a-form-item>
          <a-form-item name="confirmPassword" label="确认新密码" has-feedback>
            <a-input v-model:value="form.confirmPassword" placeholder="必须包含大小写字母和数字且不低于6位" type="password"></a-input>
          </a-form-item>
        </a-form>
        <div class="form-bootom-button">
          <a-button type="button" @click="close">取消</a-button>
          <a-button type="primary" @click="save" style="margin-left: 10px">确定</a-button>
        </div>
      </a-spin>
    </a-modal>

  </div>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'ChangePassword',
  mixins: [CommonMixin],
  components: {},
  data() {
    // 表单验证
    let validateNewPassword = (rule, value, callback) => {
      if (value === this.form.currentPassword) {
        callback(new Error("新密码不能与旧密码相同"));
      } else {
        callback();
      }
    };
    let validateNewPassword2 = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("与新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      spinning: false,
      form: {
        operatorName: "",
        currentPassword: "",
        password: "",
        confirmPassword: ""
      },
      rules: {
        operatorName: [
          {required: true, message: "请输入用户名", trigger: "change"},
        ],
        currentPassword: [
          {required: true, message: "请输入旧密码", trigger: "change"},
        ],
        password: [
          {required: true, message: "请输入新密码", trigger: "change"},
          {validator: validateNewPassword, trigger: "change"},
        ],
        confirmPassword: [
          {required: true, message: "请重新输入新密码", trigger: "change"},
          {validator: validateNewPassword2, trigger: "change"},
        ],
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById('app');
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      this.$refs.form.clearValidate();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    show() {
      this.visible = true;
    },
    save() {
      // 保存
      let that = this;
      that.$refs.form.validate().then(() => {
        that.spinning = true;
        api.changePassword(that.form).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success("修改成功，请重新登录");
          that.close();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    },

  }
}
</script>