import {axios} from './axios';

const postAction = (url, parm, timeout) => {
    let config = {
        url: url,
        method: 'post',
        data: parm ? parm : {}
    };
    if (timeout) {
        config.timeout = timeout;
    }
    return axios(config);
};

export default {
    // 登录
    login: (parm) => postAction("/api/sys/login", parm),
    // 退出
    logout: () => postAction('/api/sys/logout'),
    // 强制修改密码
    changePassword: (parm) => postAction("/api/sys/changePassword", parm),

    // 系统日志
    loginLogData: (parm) => postAction("/api/syslog/loginLogData", parm),

    // 系统资源
    resourceTree: (parm) => postAction("/api/sys/resource/resourceTree", parm),

    // 系统角色
    sysRoleList: (parm) => postAction("/api/sys/role/list", parm),
    sysRoleAdd: (parm) => postAction("/api/sys/role/add", parm),
    sysRoleDetail: (parm) => postAction("/api/sys/role/detail", parm),
    sysRoleEdit: (parm) => postAction("/api/sys/role/edit", parm),
    sysRoleDelete: (parm) => postAction("/api/sys/role/delete", parm),
    sysRoleResourceData: (parm) => postAction("/api/sys/role/resourceData", parm),
    sysRoleAllotResource: (parm) => postAction("/api/sys/role/allotResource", parm),


    // 基础数据 ------------------------------------
    // 标准医院
    hospitalList: (parm) => postAction("/api/baseconf/hospital/list", parm),
    hospitalSave: (parm) => postAction("/api/baseconf/hospital/save", parm),
    getRelationedHospital: (parm) => postAction("/api/baseconf/hospital/getRelationedHospital/" + parm),
    addHospitalRelationship: (parm) => postAction("/api/baseconf/hospital/addHospitalRelationship", parm),
    deleteHospitalRelationship: (parm) => postAction("/api/baseconf/hospital/deleteHospitalRelationship", parm),
    syncHospitalInfo: (parm) => postAction("/api/baseconf/hospital/syncHospitalInfo", parm),
    // 健康平台
    editJoinFlagOrShowFlag: (parm) => postAction("/api/baseconf/hospital/editJoinFlagOrShowFlag", parm),
    // 字典维护
    dictionaryList: (parm) => postAction("/api/baseconf/dictionaryList", parm),
    saveDictionary: (parm) => postAction("/api/baseconf/saveDictionary ", parm),
    deleteDictionary: (parm) => postAction("/api/baseconf/deleteDictionary ", parm),
    // 就诊方式维护
    treatmentDiseaseListList: (parm) => postAction("/api/baseconf/treatmentDiseaseListList ", parm),
    queryBaseDictionary: (parm) => postAction("/api/baseconf/queryBaseDictionary ", parm),
    saveTreatmentDisease: (parm) => postAction("/api/baseconf/saveTreatmentDisease ", parm),

    // 保险机构配置
    insuranceOrgList: (parm) => postAction("/api/orgconf/insuranceOrg/list", parm),
    // 机构保存
    saveUnitInfo: (parm) => postAction("/api/orgconf/insuranceOrg/saveUnitInfo", parm),
    // 保险机构-业务配置-查询菜单
    queryMenu: (parm) => postAction("/api/orgconf/insuranceOrg/queryMenu", parm),
    // 保险机构-接口配置-查询交易码
    queryHealthTransaction: (parm) => postAction("/api/orgconf/insuranceOrg/queryHealthTransaction", parm),
    // 机构配置-操作员列表
    operatorList: (parm) => postAction("/api/orgconf/insuranceOrg/operatorList ", parm),
    // 机构配置-查询该机构下角色
    querySysRoleByUnitId: (parm) => postAction("/api/orgconf/insuranceOrg/querySysRoleByUnitId ", parm),
    // 机构配置-保存操作员
    saveOperatorInfo: (parm) => postAction("/api/orgconf/insuranceOrg/saveOperator ", parm),
    // 机构配置-管理员禁用/启用
    updateDisableOrEnable: (parm) => postAction("/api/orgconf/insuranceOrg/updateDisableOrEnable ", parm),
    // 机构配置-管理员密码重置
    resetPassword: (parm) => postAction("/api/orgconf/insuranceOrg/resetPassword ", parm),
    getUuid: (parm) => postAction("/api/orgconf/insuranceOrg/getUuid", parm),

    // 机构管理-角色管理-列表
    orgRoleList: (parm) => postAction("/api/orgmgr/roleManager/roleList ", parm),
    // 机构管理-角色管理-保存
    saveOrgRole: (parm) => postAction("/api/orgmgr/roleManager/saveOrgRole ", parm),
    // 机构管理-角色管理-查询菜单
    queryMenuByCurrentOperator: (parm) => postAction("/api/orgmgr/roleManager/queryMenuByCurrentOperator", parm),
    // 机构管理-角色管理-保存菜单
    saveOrgMgrMenuInfo: (parm) => postAction("/api/orgmgr/roleManager/saveMenuInfo", parm),

    modifyPassword: (parm) => postAction("/api/orgmgr/modifyPassword", parm),

    // 用户凭证列表
    userLicenseList: (parm) => postAction("/api/userLicense/list", parm),
    getPdfUrl: (parm) => postAction("/api/userLicense/getPdfUrl", parm),

    // 疾病类别-列表
    diseaseCategoryList: (parm) => postAction("/api/icdconf/diseaseCategory/list", parm),
    // 疾病类别-配置保存
    saveDiseaseCategory: (parm) => postAction("/api/icdconf/diseaseCategory/save", parm),
    // 核保类别-列表
    underwritingCategoryList: (parm) => postAction("/api/icdconf/underwritingCategory/list", parm),
    // 核保类别-保存
    saveunderwritingCategory: (parm) => postAction("/api/icdconf/underwritingCategory/save", parm),

    // icd配置列表
    icdConfList: (parm) => postAction("/api/icdconf/list", parm),
    // icd配置保存
    saveIcd: (parm) => postAction("/api/icdconf/saveIcd", parm),

    claimsList: (parm) => postAction("/api/claims/list", parm),
    queryInsuranceList: (parm) => postAction("/api/claims/queryInsuranceList", parm),
    queryArchiveInfo: (parm) => postAction("/api/claims/queryArchiveInfo", parm),
    queryFeeDetailList: (parm) => postAction("/api/claims/queryFeeDetailList", parm),
    queryLeaveHospitalInfo: (parm) => postAction("/api/claims/queryLeaveHospitalInfo", parm),
    queryMedicalRecord: (parm) => postAction("/api/claims/queryMedicalRecord", parm),


    responsibilityList: (parm) => postAction("/api/resp/list", parm),
    queryResponsibilityInfo: (parm) => postAction("/api/resp/queryResponsibilityInfo", parm),
    getIcdListBytreatmentCode: (parm) => postAction("/api/resp/getIcdListBytreatmentCode", parm),
    saveResponsibilityInfo: (parm) => postAction("/api/resp/saveResponsibilityInfo", parm),

    queryMedicalDirectoryList: (parm) => postAction("/api/medical/list", parm),

    // 保后风险等级
    getAfterRiskResultPageList: (parm) => postAction("/api/auxiliary/after/risk/getRiskResultPageList", parm),
    auxiliaryAfterRiskAdd: (parm) => postAction("/api/auxiliary/after/risk/add", parm),
    auxiliaryAfterRiskDownloadTemplate: (parm) => postAction("/api/auxiliary/after/risk/downloadTemplate", parm),
    auxiliaryAfterRiskImportData: "/api/auxiliary/after/risk/importData",
    getAfterRiskMedicalRecordPageList: (parm) => postAction("/api/auxiliary/after/risk/getRiskMedicalRecordPageList", parm),
    auxiliaryAfterRiskExport: (parm) => postAction("/api/auxiliary/after/risk/export", parm, 1800000),
    auxiliaryAfterRiskUploadUserLicense: "/api/auxiliary/after/risk/uploadUserLicense",

    // 保后阳性率
    getAfterPositiveResultPageList: (parm) => postAction("/api/auxiliary/after/positive/getPositiveResultPageList", parm),
    auxiliaryAfterPositiveAdd: (parm) => postAction("/api/auxiliary/after/positive/add", parm),
    auxiliaryAfterPositiveDownloadTemplate: (parm) => postAction("/api/auxiliary/after/positive/downloadTemplate", parm),
    auxiliaryAfterPositiveImportData: "/api/auxiliary/after/positive/importData",
    getAfterPositiveMedicalRecordPageList: (parm) => postAction("/api/auxiliary/after/positive/getPositiveMedicalRecordPageList", parm),
    getAfterPositiveMedicalRecordDetail: (parm) => postAction("/api/auxiliary/after/positive/getPositiveMedicalRecordDetail", parm),
    auxiliaryAfterPositiveExport: (parm) => postAction("/api/auxiliary/after/positive/export", parm, 1800000),
    auxiliaryAfterPositiveUploadUserLicense: "/api/auxiliary/after/positive/uploadUserLicense",

    // 保后精准调查
    getAfterPreciseResultPageList: (parm) => postAction("/api/auxiliary/after/precise/getPreciseResultPageList", parm),
    auxiliaryAfterPreciseAdd: (parm) => postAction("/api/auxiliary/after/precise/add", parm),
    auxiliaryAfterPreciseDownloadTemplate: (parm) => postAction("/api/auxiliary/after/precise/downloadTemplate", parm),
    auxiliaryAfterPreciseImportData: "/api/auxiliary/after/precise/importData",
    getAfterPreciseMedicalRecordPageList: (parm) => postAction("/api/auxiliary/after/precise/getPreciseMedicalRecordPageList", parm),
    getAfterPreciseMedicalRecordDetail: (parm) => postAction("/api/auxiliary/after/precise/getPreciseMedicalRecordDetail", parm),
    auxiliaryAfterPreciseExport: (parm) => postAction("/api/auxiliary/after/precise/export", parm, 1800000),
    auxiliaryAfterPreciseUploadUserLicense: "/api/auxiliary/after/precise/uploadUserLicense",

    // 保前风险等级
    getBeforeRiskResultPageList: (parm) => postAction("/api/auxiliary/before/risk/getRiskResultPageList", parm),
    getBeforeRiskMedicalRecordPageList: (parm) => postAction("/api/auxiliary/before/risk/getRiskMedicalRecordPageList", parm),
    auxiliaryBeforeRiskExport: (parm) => postAction("/api/auxiliary/before/risk/export", parm, 1800000),
    auxiliaryBeforeRiskUploadUserLicense: "/api/auxiliary/before/risk/uploadUserLicense",

    // 保前阳性率
    getBeforePositiveResultPageList: (parm) => postAction("/api/auxiliary/before/positive/getPositiveResultPageList", parm),
    getBeforePositiveMedicalRecordPageList: (parm) => postAction("/api/auxiliary/before/positive/getPositiveMedicalRecordPageList", parm),
    getBeforePositiveMedicalRecordDetail: (parm) => postAction("/api/auxiliary/before/positive/getPositiveMedicalRecordDetail", parm),
    auxiliaryBeforePositiveExport: (parm) => postAction("/api/auxiliary/before/positive/export", parm, 1800000),
    auxiliaryBeforePositiveUploadUserLicense: "/api/auxiliary/before/positive/uploadUserLicense",

    // 保前精准调查
    getBeforePreciseResultPageList: (parm) => postAction("/api/auxiliary/before/precise/getPreciseResultPageList", parm),
    getBeforePreciseMedicalRecordPageList: (parm) => postAction("/api/auxiliary/before/precise/getPreciseMedicalRecordPageList", parm),
    getBeforePreciseMedicalRecordDetail: (parm) => postAction("/api/auxiliary/before/precise/getPreciseMedicalRecordDetail", parm),
    auxiliaryBeforePreciseExport: (parm) => postAction("/api/auxiliary/before/precise/export", parm, 1800000),
    auxiliaryBeforePreciseUploadUserLicense: "/api/auxiliary/before/precise/uploadUserLicense",

    // 辅助调查区域
    auxiliaryAreaData: (unitId) => postAction("/api/auxiliary/area/areaData/" + unitId),
    saveAuxiliaryArea: (parm) => postAction("/api/auxiliary/area/saveAuxiliaryArea", parm),

    // 理赔指引
    claimsGuideUnitData: () => postAction("/api/claims/guide/unitData"),
    materialConfigData: (unitId) => postAction("/api/claims/guide/materialConfigData/" + unitId),
    addClaimsGuideAccidentReasonMaterial: (parm) => postAction("/api/claims/guide/addClaimsGuideAccidentReasonMaterial", parm),
    deleteClaimsGuideAccidentReasonMaterial: (id) => postAction("/api/claims/guide/deleteClaimsGuideAccidentReasonMaterial/" + id),
    addClaimsGuideApplyItemMaterial: (parm) => postAction("/api/claims/guide/addClaimsGuideApplyItemMaterial", parm),
    deleteClaimsGuideApplyItemMaterial: (id) => postAction("/api/claims/guide/deleteClaimsGuideApplyItemMaterial/" + id),
    addClaimsGuideApplicantIdentityMaterial: (parm) => postAction("/api/claims/guide/addClaimsGuideApplicantIdentityMaterial", parm),
    deleteClaimsGuideApplicantIdentityMaterial: (id) => postAction("/api/claims/guide/deleteClaimsGuideApplicantIdentityMaterial/" + id),
    addClaimsGuidePolicyMaterial: (parm) => postAction("/api/claims/guide/addClaimsGuidePolicyMaterial", parm),
    deleteClaimsGuidePolicyMaterial: (id) => postAction("/api/claims/guide/deleteClaimsGuidePolicyMaterial/" + id),
    addClaimsGuideEntrustMaterial: (parm) => postAction("/api/claims/guide/addClaimsGuideEntrustMaterial", parm),
    deleteClaimsGuideEntrustMaterial: (id) => postAction("/api/claims/guide/deleteClaimsGuideEntrustMaterial/" + id),
    addClaimsGuideTransferMaterial: (parm) => postAction("/api/claims/guide/addClaimsGuideTransferMaterial", parm),
    deleteClaimsGuideTransferMaterial: (id) => postAction("/api/claims/guide/deleteClaimsGuideTransferMaterial/" + id),
    getClaimsGuideAccidentReasonList: (parm) => postAction("/api/claims/guide/getClaimsGuideAccidentReasonList", parm),
    saveClaimsGuideAccidentReason: (parm) => postAction("/api/claims/guide/saveClaimsGuideAccidentReason", parm),
    deleteClaimsGuideAccidentReason: (id) => postAction("/api/claims/guide/deleteClaimsGuideAccidentReason/" + id),
    getClaimsGuideApplyItemList: (parm) => postAction("/api/claims/guide/getClaimsGuideApplyItemList", parm),
    claimsGuideUploadIcon: "/api/claims/guide/uploadIcon",
    saveClaimsGuideApplyItem: (parm) => postAction("/api/claims/guide/saveClaimsGuideApplyItem", parm),
    deleteClaimsGuideApplyItem: (id) => postAction("/api/claims/guide/deleteClaimsGuideApplyItem/" + id),
    getClaimsGuideApplicantIdentityList: (parm) => postAction("/api/claims/guide/getClaimsGuideApplicantIdentityList", parm),
    saveClaimsGuideApplicantIdentity: (parm) => postAction("/api/claims/guide/saveClaimsGuideApplicantIdentity", parm),
    deleteClaimsGuideApplicantIdentity: (id) => postAction("/api/claims/guide/deleteClaimsGuideApplicantIdentity/" + id),
    getClaimsGuideMaterialList: (parm) => postAction("/api/claims/guide/getClaimsGuideMaterialList", parm),
    saveClaimsGuideMaterial: (parm) => postAction("/api/claims/guide/saveClaimsGuideMaterial", parm),
    deleteClaimsGuideMaterial: (id) => postAction("/api/claims/guide/deleteClaimsGuideMaterial/" + id),
    getClaimsGuideMaterialType: () => postAction("/api/claims/guide/getClaimsGuideMaterialType"),
    addClaimsGuideBaseMaterial: (parm) => postAction("/api/claims/guide/addClaimsGuideBaseMaterial", parm),
    deleteClaimsGuideBaseMaterial: (id) => postAction("/api/claims/guide/deleteClaimsGuideBaseMaterial/" + id),

}
